body {
  background: #403152;
  font-family: Poppins;
}

.heart {
  color: red;
}
footer {
  height: auto;
  width: auto;
  background-color: #403152;
}
.footer-content {
  font-family: Poppins;
  background-color: #403152;
}
.footer-content h3 {
  font-size: 2.1rem;
  font-weight: 500;
  text-transform: capitalize;
  line-height: 3rem;
  background-color: #403152;
}
.footer-content p {
  max-width: 500px;
  line-height: 28px;
  font-size: 14px;
  color: #cacdd2;
  background-color: #403152;
}
.social {
  font-size: 1.5rem;
  width: max-content;
  background-color: #403152;
  color: hsl(207, 90%, 72%);
  padding: 0.25rem;
  border-radius: 0.25rem;
  display: flex;
  transition: 0.4s;
  cursor: pointer;
}

.footer-bottom {
  background: 403152;
  width: 100%;
  padding: 20px;
  font-size: 13px;
  padding-bottom: 40px;
  font-family: Poppins;
}
.footer-bottom p {
  font-size: 13px;
  word-spacing: 2px;
  text-transform: capitalize;
  background-color: transparent;
  color: hsl(219, 32%, 12%);
}
.footer-bottom p a {
  color: hsl(219, 32%, 12%);
  font-size: 15px;
  text-decoration: none;
}
.footer-bottom span {
  text-transform: uppercase;
  opacity: 0.4;
  font-weight: 200;
}
.footer-menu {
  float: right;
}
.footer-menu ul {
  display: flex;
}
.footer-menu ul li {
  padding-right: 10px;
  display: block;
}
.footer-menu ul li a {
  color: #cfd2d6;
  text-decoration: none;
}
.footer-menu ul li a:hover {
  color: #27bcda;
}

@media (max-width: 500px) {
  .footer-menu ul {
    display: flex;
    margin-top: 10px;
    margin-bottom: 20px;
  }
}
